jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // var nav = $("#nav").height();
    var header =  $('#header').outerHeight();
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
    if( !$('body html').is(':animated') ) {
      $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });

  var $onOff = $('.on_off'),
      $noChange = "none";
  $onOff.hover(function() {
    $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
  },
  function() {
    if (!$(this).hasClass($noChange)) {
      $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
    }
  });

  var $backBtn = $('#back-btn');
    $backBtn.on('click', function() {
    history.back();
  });

  $(".voice_ttl").on("click", function() {
    $(this).next().slideToggle();
    $(this).toggleClass("active");//追加部分
  });
});
